<template>
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
  <section>
    <b-container>
      <b-alert
      variant="danger"
      :show = "showAlert"
    >
      <h4 class="alert-heading">
        Alert!
      </h4>
      <div class="alert-body">
        <span>Please complete the quiz</span>
      </div>
    </b-alert>
      <b-row>
        <!-- Accordions -->
        <b-col md="12" class="mx-auto">
          <div class="question">
            <b-card>
              <ValidationObserver ref="loginValidation">
              <b-form @submit.prevent="storeAnswer">
                <b-card-header header-tag="header" class="bg-primary mb-4">
                    <h4 class="mb-0 w-100 text-center font-weight-bold text-white"> {{quiz.name}}</h4>
                  </b-card-header>
                <b-card-body>
              
                <div class="question mb-4" v-for="(quiz,index) in quizzes">
                  <ValidationProvider rules="requiredRadioBtn" :name="'question'+quiz.id" v-slot="{ errors }">
                  <h4 class="mb-4 d-flex"><span class="mr-1">Q{{index+1}}</span>{{quiz.question}}</h4>
                  <div class="question-radio">
                      <input type="radio" v-model="responses['question'+quiz.id]" @change="getRadioValue($event, quiz.id, quiz.quiz, quiz.option_1, quiz.answer)" :name="'question'+quiz.id" value="1" :id="'questionRadio1'+quiz.id">
                      <label :for="'questionRadio1'+quiz.id"><span class="option-count mr-2">A</span>{{quiz.option_1}}</label>
                  </div>
                  <div class="question-radio">
                      <input type="radio" v-model="responses['question'+quiz.id]" @change="getRadioValue($event, quiz.id, quiz.quiz, quiz.option_2, quiz.answer)" :name="'question'+quiz.id" value="2" :id="'questionRadio2'+quiz.id">
                      <label :for="'questionRadio2'+quiz.id"><span class="option-count mr-2">B</span>{{quiz.option_2}}</label>
                  </div>
                  <div class="question-radio">
                      <input type="radio" v-model="responses['question'+quiz.id]" @change="getRadioValue($event, quiz.id, quiz.quiz, quiz.option_3, quiz.answer)" :name="'question'+quiz.id" value="3" :id="'questionRadio3'+quiz.id">
                      <label :for="'questionRadio3'+quiz.id"><span class="option-count mr-2">C</span>{{quiz.option_3}}</label>
                  </div>
                  <div class="question-radio">
                      <input type="radio" v-model="responses['question'+quiz.id]" @change="getRadioValue($event, quiz.id, quiz.quiz, quiz.option_4, quiz.answer)" :name="'question'+quiz.id" value="4" :id="'questionRadio4'+quiz.id">
                      <label :for="'questionRadio4'+quiz.id"><span class="option-count mr-2">D</span>{{quiz.option_4}}</label>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                 
              </b-card-body>
              <!--<b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            
            
          >
            Create
          </b-button> -->
            <div class="d-flex justify-content-between align-items-center">
                  <button @click="reset()" class="btn btn-secondary rounded-pill" type="button">Reset</button>
                  <button class="btn btn-success rounded-pill" type="submit">Submit</button>
            </div>
                
            </b-form>
            </ValidationObserver>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  </b-overlay>
</template>

<style scoped>
    .question-radio input[type="radio"]{
    display: none;
}
.question-radio label {
    box-shadow: 0px 2px 11px rgb(69 65 164 / 6%), 0px 4px 10px rgb(31 37 89 / 7%);
    border-radius: 8px;
    background-color: #ffffff;
    border: 1px solid #c3efd7;
    padding: 20px 15px;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
    cursor: pointer;
}
.question-radio label .option-count {
    border-radius: 50%;
    box-shadow: 0px 2px 11px rgba(69, 65, 164, 0.06), 0px 4px 10px rgba(31, 37, 89, 0.07);
    width: 40px;
    height: 40px;
    border: 1px solid #ddd;
    line-height: 40px;
    text-align: center;
}
.question-radio input:checked + label {
    background-color: #c3efd7;
    color: black !important;
}
.question-collapse .card-header{
    cursor: pointer;
}
.rotate-icon{
    float:right;
  }
  .question-collapse .card-header:not(.not-collapsed) .rotate-icon{
    transform: rotate(180deg);
  }
</style>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import MultipleQuestionService from '@/services/multipleQuestion.service.js';
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BTable, BAlert, BProgress,BButton, BForm, BContainer, BRow, BCol, BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BLink, BIcon,BCard,BCardHeader,BCardBody,VBToggle,BCollapse} from 'bootstrap-vue'
  export default {
    components: {
        BCardCode,
        BTable,
        BProgress,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BOverlay,
        BRow,
        BCol,
        BButton,
        BLink,
        BIcon,
        BContainer,
        BCard,
        BCardHeader,
        BCardBody,
        BCollapse,
        BForm,
        BAlert
    },
    
    directives: {
        Ripple,
        'b-toggle': VBToggle,
    },
    data() {
      return {
        quizzes: {},
        id: this.$route.params.id,
        mChoice: null,
        selectedChoices: [],
        questionCount: 0,
        quiz: {},
        showOverlay: false, 
        responses: {},
        showAlert: false
      }
    },
    watch:{
        '$route'(to) {
            this.id = to.params.id;
        } 
    },
    methods: {
        reset() {
          location.reload()
        },
        getQuizzes()
        {
            this.showOverlay = true;
            MultipleQuestionService.getStudentMCQ(this.id).then(response => {
                this.showOverlay = false;
                this.quizzes = response.data.data.mcqs;
                this.questionCount = response.data.data.mcqs.length
                if(this.questionCount > 0 && response.data.data.mcqs[0].quiz) {
                  this.quiz = response.data.data.mcqs[0].quiz;
                }
                
                //console.log(response.data.data)
            }).catch(error => {
              this.showOverlay = false;
              this.errors = error.response
            });
        },
        getRadioValue(event, id, quiz, selectedText, answer)
        {
            if(this.selectedChoices.length > 0) {
                let getIndex = this.selectedChoices.findIndex(choice => {
                    return choice.question_id == id
                })
                if(getIndex != -1) {
                    this.selectedChoices.splice(getIndex, 1);
                }

            }
            var obj = {
                selectedAnswer: event.target.value,
                question_id: id,
                selectedText: selectedText,
                answer: answer
            }
            this.selectedChoices.push(obj)
            //console.log(this.selectedChoices)
        },
        storeAnswer()
        {
          this.$refs.loginValidation.validate().then(success => {
            if(!success) {
              this.showAlert = true;
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
              return;
            }
            this.showAlert = false;
            this.showOverlay = true;
            let getData = this.storeFormData();
            MultipleQuestionService.storeAnswer(getData).then(response => {
                this.showOverlay = false;
                this.$router.push(`/student-quiz-result/${response.data.result_id}`)
                
            }).catch(error => {
              this.showOverlay = false;
              this.errors = error.response
            });
          })
            // this.showOverlay = true;
            // let getData = this.storeFormData();
            // MultipleQuestionService.storeAnswer(getData).then(response => {
            //     this.showOverlay = false;
            //     console.log(response)
            //     this.$router.push(`/student-quiz-result/${response.data.result_id}`)
                
            // }).catch(error => {
            //   this.showOverlay = false;
            //   this.errors = error.response
            // });
        },
        storeFormData()
        {
            let formData = new FormData();
            formData.append('selectedOptions', JSON.stringify(this.selectedChoices));
            formData.append('quiz_id', this.quiz.id);
            formData.append('lesson_id', this.quiz.lesson_id);
            formData.append('questionCount', this.questionCount);
            return formData;
        },
    },
    
    created ()
    {
        this.getQuizzes()
    }
  }
</script>